@import url("../../assets/styles/common-style.css");

/* Landing Page */
.landing-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.dpadding {
  padding: 0px 4px;
}

.form-selections {
  width: 250px;
}

.pdf-sections {
  position: relative;
}

.report-wrapper {
  width: 100%;
  margin: 0 auto;
}

.report-content-sections {
  width: 90%;
  margin: 0 auto;
}

/* Header Logo */
.header-logo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 71px;
}

.header-logo .logo-icon {
  width: 170px;
  height: 30px;
}

.makeStyles-button-1 {
  margin: 0px;
}

.footer-logo {
  display: none;
  align-items: center;
  justify-content: flex-start;
}

.logo-icon {
  width: 130px;
  height: 35px;
}

.logo-icon img {
  width: 100%;
  height: 100%;
}

/* report-title */
.report-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-sections {
  height: calc(100vh - 65px);
  position: relative;
}

.report-bg {
  height: calc(100vh - 65px);
  width: calc(100vw - 148px);
}

.report-bg-img {
  height: 100%;
  width: 100%;
}

.report-bg-img img {
  object-fit: fill;
  height: 100%;
  width: 100%;
}

.report-title {
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  top: 30px;
  left: 50px;
}

.heading-text {
  font-size: 28px;
  font-weight: bold;
  font-style: italic;
}

/* .country-details {
  position: absolute;
  bottom: 7.5%;
  left: 24%;
}
.published {
  position: absolute;
  bottom: 3%;
  display: flex;
  left: 28%;
}
.published textarea {
  background: transparent;
  resize: none;
  height: 16px;
  font-size: 14px;
} */
.country-details label,
.country-details p,
.country-details span {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  line-height: 33px;
}

.table-content-title {
  text-align: center;
  display: block;
  font-size: 22px;
  line-height: 20px;
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
}

.table-content-heading {
  margin-left: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.common-heading,
.table-content-list li a {
  color: #000;
  font-size: 18px;
  display: block;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 10px;
}

.common-heading,
.table-content-list li a label {
  cursor: pointer;
}

/* Tittle Content */
.table-content-list li a {
  display: flex;
}

.table-content-list li a .title {
  order: 1;
}

.table-content-list li a .chapter {
  order: 3;
  display: none;
}

.table-content-list li a::after {
  display: none;
  background-image: radial-gradient(circle,
      currentcolor 1px,
      transparent 1.5px);
  background-position: bottom;
  background-size: 5px 5px;
  background-repeat: space no-repeat;
  content: "";
  flex-grow: 1;
  height: 1em;
  order: 2;
}

/* .table-content-list li {
  display: flex;
  justify-content: space-between; */
/* } */
.common-sub-heading {
  /* text-indent: 15px; */
  color: #000;
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}

.common-min-heading {
  /* text-indent: 15px; */
  color: #000;
  font-size: 14px;
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}

.common-para {
  text-indent: 50px;
  font-size: 14px;
  text-align: justify;
  margin-bottom: 10px;
  color: #000;
}

.text-area {
  /* width: 80%;
  margin: 0 auto; */
  text-align: center;
  margin-bottom: 10px;
}

.text-area textarea {
  resize: none;
  width: 650px;
  min-height: 40px;
  /* width: 100%; */
  border: 2px solid #e2dfdf;
  border-radius: 5px;
}

/* .text-area textarea:focus-visible {
  outline-offset: none;
} */
.formula {
  text-align: center;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.crop-showing-img {
  width: 90%;
  height: 90%;
  margin: 0 auto;
}

.ndi-img {
  width: 60%;
}

.crop-showing-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.crop-identify {
  margin-bottom: 10px;
}

.crop-identify .crop-showing-img {
  width: 60%;
}

/* .crop-identify .crop-showing-img img { */
/* border-right: 1px solid #000;
  border-bottom: 1px solid #000; */
/* } */
.figures {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin: 10px 0px;
  font-weight: 600;
}

.field-photographs-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.field-photographs-list li {
  flex-basis: 40%;
  margin-bottom: 20px;
}

.annexure {
  margin-bottom: 25px;
}

/* NDVI */
.ndvi-images,
.ndwi-images {
  flex-wrap: wrap;
  display: flex;
  /* justify-content: space-between; */
}

.ndvi-images li,
.ndwi-images li {
  flex-basis: 33%;
  margin-bottom: 10px;
}

.export {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.export-pdf {
  margin-bottom: 10px;
}

.export-pdf,
.export-doc {
  background-color: #272b61;
  padding: 10px;
  border-radius: 19px;
  cursor: pointer;
}

.export-doc img,
.export-pdf img {
  cursor: pointer;
}

.back-btn {
  position: absolute;
}

.page-loader-sections {
  display: flex;
  margin: 0px;
  height: 100%;
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 10em;
  overflow: show;
  margin: auto;
  top: -150px;
  left: 0;
  bottom: 0;
  right: 0;
}

.field-imgs {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.image-item {
  flex-basis: 40%;
  margin-top: 10px;
}

.image-item textarea {
  width: 80%;
}

.upload-img {
  border: 2px solid #000;
  border-radius: 6px;
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
}

.upload-img img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.img-sections {
  position: relative;
}

.image-item__btn-wrapper {
  display: none;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000000c7;
}

.img-sections:hover .img-overlay {
  opacity: 1;
}

.hover-icon {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.hover-icon img {
  width: 100%;
  height: 100%;
}

.image-item__btn-wrapper {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  color: #696969;
  opacity: 0.4;
}

/* photographs-card */
.photographs-card {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.photographs-card li {
  flex-basis: 40%;
  margin-top: 10px;
  /* margin: 20px 0px; */
}

.photographs-card li .text-area textarea {
  width: 80%;
  /* min-height: 20px; */
}

.field-img {
  position: relative;
  border: 2px dashed #dadce0;
  border-radius: 6px;
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
}

.field-img label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  color: #696969;
  opacity: 0.4;
}

.field-img .img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000000c7;
}

.field-img:hover .img-overlay {
  opacity: 1;
}

.hover-icon .upload-img {
  color: #fff;
  opacity: 1;
}

.img-preview {
  width: 100%;
  height: 100%;
}

.img-preview .preview {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.upload-img input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 0rem;
}

/* Loader */
.loader-container {
  display: flex;
  margin: 0px;
  height: 100vh;
  background: #edf3f3;
}

.loader {
  height: 200px;
  width: 200px;
  margin: auto;
}

.loader>div {
  height: inherit;
  width: inherit;
  position: absolute;
  animation-name: rotate;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader>div>div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #272b61;
  position: absolute;
  top: 0%;
  right: 50%;
  transform: translate(50%, 0%);
}

.loader>div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader>div:nth-child(3) {
  animation-delay: 0.4s;
}

.loader>div:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  50% {}

  100% {
    transform: rotate(360deg);
  }
}

.upload-remove {
  display: none;
}

.photographs-card .field-card:nth-child(5n),
.photographs-card li:nth-child(6n) {
  page-break-before: always;
  /* margin-top: 100px; */
}

/* @print */
@media print {
  .report-wrapper {
    position: relative;
  }

  .page-counter {
    /* position: absolute;
    bottom: 70px;
    right: 0px; */
  }

  body {
    counter-reset: page;
  }

  /*
  .page-counter:after {
    counter-increment: page;
    content: "Page " counter(page);
  } */
  /* 
  #total-counter {
    counter-reset: totalPages;
  }
  #total-counter span {
    counter-increment: totalPages;
  }

  .page-counter:after {
    counter-increment: page;
    content: "Page " counter(page) " / " counter(totalPages);
  } */
  /* .page-number:before {
    content: "Page: " counter(page) " of " counter(pages);
    counter-increment: page;
  } */
  /* #content {
    display: table;
  }

  #pageFooter {
    display: table-footer-group;
  }
  #pageFooter:after {
    counter-increment: page;
    content: "Page " counter(page);
    left: 0;
    top: 100%;
    white-space: nowrap;
    z-index: 20px;
    -moz-border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
  } */
  /* .page {
    counter-reset: page;
  }
  .page .page-number {
    display: block;
    white-space: nowrap;
  }
  .page .page-number:after {
    counter-increment: page;
    content: counter(page);
  }
  .page:after {
    display: block;
    content: "Number of pages: " counter(page);
  } */
  .page-break {
    margin-top: 100px;
    page-break-before: always;
  }

  .nobottom {
    /* display: flex;
       align-items: flex-end; */
    position: relative;
    height: calc(100% - 150px);
    /* height: 86%; */
    /* bottom: 40px; */
    /* right: 40px; */
    /* float: right;
       float: inline-end; */
  }

  .number {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .number:after {
    counter-increment: page;
    content: counter(page);
  }

  .tittlebottom {
    position: relative;
    height: 100%;
    width: 100%;
    /* height: calc(100% - 50px); */
  }

  .chm-img .page-break-after.nobottom:first-child {
    height: calc(100% - 220px);
  }

  .chm-img .page-break-after:not(:first-child) {
    page-break-before: always;
    margin-top: 100px;
  }

  .header-logo {
    position: fixed;
    top: 0;
    width: 90%;
  }

  .footer-logo {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 90%;
    margin-bottom: 30px;
  }

  .ndwi-images li,
  .ndvi-images li {
    flex-basis: 20%;
  }

  .ndvi-images .crop-showing-img,
  .ndwi-images .crop-showing-img {
    height: 145px;
    width: 100%;
    object-fit: contain;
  }

  .crop-identify .crop-showing-img,
  .dis-wise .crop-showing-img,
  .crop-cal .crop-showing-img {
    height: 10% !important;
  }

  .pieChat {
    height: 100%;
  }

  .crop-showing-img.ndi-img {
    height: 60px !important;
  }

  .crop-showing-img.ndvi-chart,
  .crop-showing-img.ndwi-chart {
    height: 200px;
  }

  .crop-showing-img {
    height: 500px;
  }

  .crop-list .crop-showing-img {
    height: 700px;
  }

  .report-sections {
    height: 100vh;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    z-index: 99999;
  }

  .report-bg-img {
    height: 100vh;
  }

  .report-bg {
    width: 100%;
  }

  .report-title {
    width: 100%;
    position: absolute;
    top: 30px;
    text-align: center;
    left: 0px;
  }

  .header-logo {
    height: 100px;
  }

  /* .country-details {
    bottom: 8.5%;
  }
  .published textarea {
    height: 30px;
    font-size: 18px;
  } */
  .empty-page {
    height: 100vh;
  }

  .table-content-list li a .chapter {
    order: 3;
    display: block;
  }

  .table-content-list li a::after {
    display: block;
  }

  .upload-img {
    height: 300px;
  }

  .upload-remove {
    display: none;
  }

  .country-details label,
  .country-details p,
  .country-details span,
  .heading-text,
  .formula,
  .figures,
  .common-min-heading,
  .common-sub-heading,
  .table-content-title,
  .common-heading,
  .table-content-list li a {
    font-weight: 500;
  }

  /* .field-photographs {
    width: 50px;
    height: 50px;
  } */
  /* .photographs-card .field-card:nth-child(5n),
  .photographs-card li:nth-child(6n) {
    page-break-before: always;
    margin-top: 100px;
  } */
}