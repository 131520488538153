@font-face {
  font-family: "poppins-regular";
  src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "vag-round-regular";
  src: url("../fonts/VAG\ Rounded\ Regular.ttf");
}
@font-face {
  font-family: "vag-round-std-regular";
  src: url("../fonts/VAGRoundedStd-Light.ttf");
}
/* Common */
body {
  /* font-family: "poppins-regular"; */
  font-family: "vag-round-std-regular";
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0px;
}
a {
  text-decoration: none;
  color: #000;
}
ul,
li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.width-10 {
  width: 10%;
}
table {
  width: 100%;
}
table,
th,
td {
  text-align: start;
  border: 1px solid black;
  border-collapse: collapse;
}
td {
  padding: 12px 5px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}
textarea {
  font-family: "vag-round-std-regular";
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}
.top-0 {
  margin-top: 0px !important;
}
